<div class="theForm">
  <div class="container">
    <div class="row">
      <div class="col-md">
        <div class='intro d-md-none'>
          <p class="txt">{{{leadMessage}}}</p>
          <a href="{{{form_link}}}" class="btn btn-block btn-secondary btn-lg">Contact us now</a>
        </div>
        <form 
          class="form text-center d-none d-md-block" 
          id="contactForm" 
          data-conversion-event-code="{{{conversion_event_code}}}">
          <p class="txt">{{{leadMessage}}}</p>
          <a href="{{{form_link}}}" class="btn btn-block btn-secondary btn-lg">Contact us now</a>

        </form>
        <div class="phone">Or call <a onclick="gtag_report_event('tel:{{{phone_number}}}', '{{{conversion_event_code}}}');" href="tel:{{{phone_number}}}">{{{phone_number_formatted}}}</a></div>
      </div>
    </div>
  </div>

  <div id="messageSuccess" class="container" style="display:none">
    <blockquote>
      <p>
        "Conversation is a meeting of minds with different memories and habits. When minds meet, they don't just exchange facts: they transform them, reshape them, draw different implications from them, engage in new trains of thought.<br> <span class="dataDigestGold"><b>Conversation doesn't just reshuffle the cards: it creates new cards.</b></span>"
      </p>
      <footer>
        - <cite>Theodore Zeldin</cite>
      </footer>
    </blockquote>
  </div>
</div>