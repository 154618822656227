<div class="container mt-2">
  <div class="row">
    <div class="col-md">
      {{>scrolltarget}}
      <h2 class="text-center">Who we are</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md">
      <p>DataDIGEST (Pty) Ltd is a software and technology company and Microsoft Partner. Founded in 2007, we provide small, highly-experienced technical teams 
        that get things done, fast. We are experts in building solutions for Azure using .NET Core, Blazor, React and Xamarin across the finance, health, agriculture
        and travel industries. We are located in Cape Town, South Africa and Sydney, Australia.</p>
    </div>
  </div>
</div>