
<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="techSlider">
                {{>techslidercard imgSrcUrl='/img/c_abileneproject.png' imgTitle='The Abilene Project'}}
                {{>techslidercard imgSrcUrl='/img/c_ads.png' imgTitle='Appollo Digital Solutions'}}
                {{>techslidercard imgSrcUrl='/img/c_agrisoil.png' imgTitle='Agri Soil'}}
                {{>techslidercard imgSrcUrl='/img/c_bryte.png' imgTitle='Bryte'}}
                {{>techslidercard imgSrcUrl='/img/c_cape_town_film_studios.png' imgTitle='Cape town film studios'}}
                {{>techslidercard imgSrcUrl='/img/c_capitec.png' imgTitle='Capitec bank'}}
                {{>techslidercard imgSrcUrl='/img/c_CEQS.png' imgTitle='CEQS'}}
                {{>techslidercard imgSrcUrl='/img/c_clay_pigeon.png' imgTitle='Clay pigeon adventures'}}
                {{>techslidercard imgSrcUrl='/img/c_dr_chris_pistorius_dental.png' imgTitle='Dr. Chris Pistorius Dental'}}
                {{>techslidercard imgSrcUrl='/img/c_dwt.png' imgTitle='DWT'}}
                {{>techslidercard imgSrcUrl='/img/c_earth_apple.png' imgTitle='Earth Apple'}}
                {{>techslidercard imgSrcUrl='/img/c_edly.png' imgTitle='MyEdly'}}
                {{>techslidercard imgSrcUrl='/img/c_emhc.png' imgTitle='eMental Health Clinic'}}
                {{>techslidercard imgSrcUrl='/img/c_fsg.png' imgTitle='Field Solutions Group'}}
                {{>techslidercard imgSrcUrl='/img/c_ful.png' imgTitle='FitUniLife'}}
                {{>techslidercard imgSrcUrl='/img/c_Gey_van_Pittius.png' imgTitle='Gey van Pittius'}}
                {{>techslidercard imgSrcUrl='/img/c_gogotravel.png' imgTitle='GoGo Travel"'}}
                {{>techslidercard imgSrcUrl='/img/c_granadilla.png' imgTitle='Granadilla"'}}
                {{>techslidercard imgSrcUrl='/img/c_hn.png' imgTitle='HN Construction'}}
                {{>techslidercard imgSrcUrl='/img/c_itm.png' imgTitle='ITM South Africa'}}
                {{>techslidercard imgSrcUrl='/img/c_ltk.png' imgTitle='Leap to Know'}}
                {{>techslidercard imgSrcUrl='/img/c_mitoken.png' imgTitle='Mi-Token'}}
                {{>techslidercard imgSrcUrl='/img/c_nsf.png' imgTitle='NSF'}}
                {{>techslidercard imgSrcUrl='/img/c_OneStopAfrica.png' imgTitle='One stop Africa travel'}}
                {{>techslidercard imgSrcUrl='/img/c_ongegund_lodge.png' imgTitle='Ongegund Lodge'}}
                {{>techslidercard imgSrcUrl='/img/c_oosthuizenkie.png' imgTitle='Oosthuizen and Kie'}}
                {{>techslidercard imgSrcUrl='/img/c_Optimate.png' imgTitle='Optimate'}}
                {{>techslidercard imgSrcUrl='/img/c_overberg_asset.png' imgTitle='Overberg Asset Managers'}}
                {{>techslidercard imgSrcUrl='/img/c_pipandtree.png' imgTitle='PipAndTree.com'}}
                {{>techslidercard imgSrcUrl='/img/c_retailSolutions.png' imgTitle=''}}
                {{>techslidercard imgSrcUrl='/img/c_sakg.png' imgTitle='SA Lime and Gypsum'}}
                {{>techslidercard imgSrcUrl='/img/c_sallies.png' imgTitle='Sallies Silica'}}
                {{>techslidercard imgSrcUrl='/img/c_SchoolAdvisor.png' imgTitle='School Advisor'}}
                {{>techslidercard imgSrcUrl='/img/c_somerset_college.png' imgTitle='Somerset College'}}
                {{>techslidercard imgSrcUrl='/img/c_sls.png' imgTitle='Surf life saving Australia'}}
                {{>techslidercard imgSrcUrl='/img/c_SomersetBlinds.png' imgTitle='Somerset Blinds'}}
                {{>techslidercard imgSrcUrl='/img/c_westvaal.png' imgTitle='Westvall motor holdings'}}
                {{>techslidercard imgSrcUrl='/img/c_witkop.png' imgTitle='Witkop Flourspar mine'}}
            </div>
        </div>
    </div>
</div>