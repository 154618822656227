<script src="https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.js" type="text/javascript"></script>
<link rel="stylesheet" type="text/css" href="https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.css"/>
<script type="text/javascript">
    function renderEstimateSurvey(parentElementId, FirstName, country) {

        let se = (country === 'ZA')
                ? new SurveyEmbed("bKxj0r9RykKgA2OaDs-Q6K8dxdU7wpFMgbVBeFvUWB1UMzk5STBCOEQ4QldFRDA4SUNEQkVSREtBQy4u", "https://forms.office.com/", "https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/", "false")
                : new SurveyEmbed("bKxj0r9RykKgA2OaDs-Q6K8dxdU7wpFMgbVBeFvUWB1UMlFYT1E5QzlHS0QyMEIzREpNWDVCS0ZCTy4u", "https://forms.office.com/", "https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/", "false");

        // const se = new SurveyEmbed("bKxj0r9RykKgA2OaDs-Q6K8dxdU7wpFMgbVBeFvUWB1UMzk5STBCOEQ4QldFRDA4SUNEQkVSREtBQy4u","https://forms.office.com/","https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/","false");
        const context = {
            "First Name": FirstName,
            "Last Name": country
        };
        se.renderInline(parentElementId, context);
    };

    function renderNDASurvey(parentElementId, FirstName, LastName) {
        var se = new SurveyEmbed("bKxj0r9RykKgA2OaDs-Q6K8dxdU7wpFMgbVBeFvUWB1UNkw4MjZLRDBZWlQ4MUY5N00wVEhIQ045Sy4u", "https://forms.office.com/", "https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/", "false");
        var context = {"First Name": FirstName, "Last Name": LastName,};
        se.renderInline(parentElementId, context);
    };

    function renderCallbackSurvey(parentElementId, FirstName, LastName) {
        var se = new SurveyEmbed("bKxj0r9RykKgA2OaDs-Q6K8dxdU7wpFMgbVBeFvUWB1URTkzWTlNNjZPM0ZLR0xBUzZIOFlJQTBSMi4u", "https://forms.office.com/", "https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/", "false");
        var context = {"First Name": FirstName, "Last Name": LastName,};
        se.renderInline(parentElementId, context);
    };
</script>