---
_options:
  layout: src/layouts/main.static.hbs
  partials:
    - src/partials/theform.static.hbs
    - src/partials/techslider.static.hbs
    - src/partials/techslidercard.static.hbs
    - src/partials/clientslider.static.hbs
    - src/partials/scrollicon.static.hbs
    - src/partials/scrolltarget.static.hbs
    - src/partials/contactusbutton.static.hbs
    - src/partials/howwework.static.hbs
    - src/partials/whoweare.static.hbs
    - src/partials/appservice.static.hbs
    - src/partials/serverless.static.hbs
    - src/partials/azure_data.static.hbs
    - src/partials/flexible_resourcing.static.hbs

page-id: azure
title: Azure Application Architecture Experts
current-nav-item: Azure
---

{{>theform leadMessage='Small, highly-experienced teams that get things done, <span class="dataDigestGold"><b>fast</b></span>'
    conversion_event_code='AW-794917297/-muBCJmluokBELHzhfsC'
    phone_number='+27212005969'
    phone_number_formatted='+27 (0)21 200 5969'
    form_link='contactus.html'}}


{{>scrollicon}}

 <div class="hide-on-landscape theWords">
  <div>
    Microsoft Azure Experts<br/>
    Solution Design and Architecture<br/>
    New Mobile and Web Apps<br/>
    Existing App Migrations<br/>
    Training and Advice<br/>
    Consulting and Project Rescue
  </div>
</div>

<div class="mantra">
  <div class="txt">
    <span class="inner">
     azure
      <!--Potential-->
      <span class="block">un</span>
      leashed
    </span>
  </div>
</div>

<div id="aa_particles"></div>

{{>whoweare}}

<div class="container">
  <div class="row">
    <div class="col-md">
      <h2>Unleashing Potential with Technology</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>
        From short-term consulting, through to assembling a complete product development and delivery team, we can help you
        unleash the potential of the cloud with Microsoft Azure.
      </p>
      {{>appservice}}
      {{>serverless}}
      {{>azure_data}}
      {{>flexible_resourcing}}

      <h3>Advice</h3>
      <p>
        Need help getting started with moving to Azure? Or do you need an audit of your existing solution?
        We can help by providing detailed reports into the current position of your solutions with clear, costed
          roadmaps for achieving your objectives.
      </p>
    </div>
  </div>
</div>

{{>howwework}}

<div class="container">
  <div class="row">
    <div class="col-md">
      <h2 class="text-center">Some of our clients</h2>
    </div>
  </div>
</div>

{{>clientslider}}

{{>contactusbutton}}
