---
_options: 
  layout: src/layouts/main.static.hbs
  partials:
    - src/partials/theform.static.hbs
    - src/partials/mobiletechslider.static.hbs
    - src/partials/techslidercard.static.hbs
    - src/partials/clientslider.static.hbs
    - src/partials/scrollicon.static.hbs
    - src/partials/scrolltarget.static.hbs
    - src/partials/contactusbutton.static.hbs
    - src/partials/howwework.static.hbs
    - src/partials/whoweare.static.hbs
    
page-id: progressive-web-apps
title: Progressive Web Applications for Web, Mobile and Desktop
current-nav-item: progressive-web-apps
---
{{>theform leadMessage='The next generation of mobile and desktop apps. Start a <span class="dataDigestGold"><b>conversation</b></span> now to find out more' 
  conversion_event_code='AW-794917297/nGm6CI3LxZcBELHzhfsC'
  phone_number='+27212005969'
    phone_number_formatted='+27 (0)21 200 5969'
    form_link='contactus.html'}}

{{>scrollicon}}

<div class="hide-on-landscape theWords">
  <div>
    PWAs<br/>
    iOS<br/>
    Android<br/>
    Windows<br/>
    Linux
  </div>
</div>

<div class="mantra">
  <div class="txt">
    <span class="inner">
     imagination
      <!--Potential-->
      <span class="block">un</span> 
      leashed
    </span>
  </div>
</div>

<div id="aa_particles"></div>




{{>whoweare}}

<div class="container">
  <div class="row">
    <div class="col-md">
      <h2>Progressive Web Apps</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Take your web application to the next level with the introduction of Progressive Web App features, allowing your app to be installed on iOS, Android, Windows and Linux devices.</p>
      <h2></h2>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h3>Why Choose a PWA</h3>
      <p>A web application, especially one designed responsively, is the most cost-effective way of getting your app or product in front of the largest number of users. 
        All they need is an internet connection and a device (phone, tablet or desktop) with a browser installed. For a huge number of use-cases, this is all you will ever need.</p>
      <p>But web apps still have some limitations compared to an app installed onto your phone or desktop. For starters, despite the huge advances made in web technology, 
        the user experience is not going to be as slick and fast as a native app. Load times will be longer, there will be a slight delay after every user interaction and 
        some slick features of a native app are going to be expensive to replicate in a web app. And what if your user doesn’t have an internet connection available? Your 
        app is going to be unusable. Yet the biggest reason a native app will often be chosen is that it requires access to features of the device that the operating system 
        (especially iOS), keeps far away from web apps.</p>
      <p>Now, developing native apps can be an expensive exercise. Often times you are developing a web app to compliment your native app as well. By making that web app 
        a Progressive Web Application (PWA) you gain all the benefits of a native app for a fraction of the cost. </p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h3>What is a PWA</h3>
      <p>A PWA is a regular web application, that loads just like a regular web page, but has been enhanced with certain features to allow it to offer native app functionality 
        like working offline, push notifications or access to device hardware. A PWA should:</p>
      <ul>
        <li>Be safe (only served over HTTPS)</li>
        <li>Work for every user, regardless of browser or device (desktop, mobile, tablet)</li>
        <li>Work offline or on intermittent connections</li>
        <li>Feel like an app</li>
        <li>Be discoverable (search engines should recognise them as apps, not websites)</li>
        <li>Be installable onto the users device without the hassle of an app store</li>
        <li>Be easily shareable via a URL</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col">
        <p>At DataDIGEST we build both traditional web apps, progressive web apps and native <a href="mobile-app-development.html">mobile apps</a>. Start a conversation with us today and we will help you choose the right technology for your needs.</p>
    </div>
  </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md">
      <h2 class="text-center">Some of our clients</h2>
    </div>
  </div>
</div>

{{>clientslider}}

{{>howwework}}

{{>contactusbutton}}