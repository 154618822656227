'use strict';
//console.log('Tasty Brunch, just trying to use jQuery!', $('body'));

document.addEventListener('DOMContentLoaded', function() {
  if ((window.location.hostname.indexOf("localhost") > -1) || (window.location.hostname.indexOf("dev") > -1) || (window.location.hostname.indexOf("codepen") > -1)) {

  let bodyElement = document.querySelector('body');

  bodyElement.insertAdjacentHTML('beforeend', `<aside id="breakpoint-helper" tabindex="-1" aria-hidden="true"></aside>`);
  console.log('%c ✔ breakpoint helper', 'color: green');
  }

  //console.log('%c ✔ Initialized app', 'color: green');
});