---
_options: 
  layout: src/layouts/main.static.hbs
  partials:
    - src/partials/contactusbutton.static.hbs
  
title: Your technology product unleashed
description: Lean and fast is the mantra for modern projects and product development. It is more than software development. It is identifying value opportunities, delivering features fast, measuring their impact and adapting fast. We can oversee the process, fill a gap in your capabilities, boost your capacity or deliver the whole project.
page-id: product 
current-nav-item: Product 
---
<div class="content mantra">
  <div class="txt">
    <span class="inner">
     your product
      <!--Potential-->
      <span class="block">un</span> 
      leashed
    </span>
  </div>
</div>

<div id="content_particles"></div>

<div class="container mt-2">
    <div class="row">
        <div class="col">
            <h1>Unleasing the potential of MyEdly.com</h1>
        </div>
    </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
     <p>MyEdly.com is the only EdTech platform in Africa that focusses on the classroom teacher. Its aim is to put the best EdTech tools and content in the hands of individual teachers, saving time and improving lessons. More time for teaching means better teaching and a future generation who’s potential can be unleashed through quality education.</p>
     <p>DataDIGEST is the product development partner for MyEdly.com. The initial brief was simple, but challenging:</p>
     <ol>
         <li>Get to market fast</li>
         <li>Keep the costs low</li>
         <li>Be prepared to scale fast</li>
         <li>Measure, learn and adapt the product</li>
     </ol>
     <p>We followed a broad, six-step plan to deliver on this brief:</p>
     <ol>
         <li><a href="#valueProp">Establish the value proposition</a></li>
         <li><a href="#mvp">Identify the MVP</a></li>
         <li><a href="#metrics">Establish metrics</a></li>
         <li><a href="#launch">Build and launch fast</a></li>
         <li><a href="#measure">Measure</a></li>
         <li><a href="#adapt">Adapt</a></li>
     </ol>
    </div>
  </div>
</div>

<a id="valueProp"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>Establish the value proposition</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Before starting on any project or product development, it is important that everyone has a shared understanding of the value proposition. This is a statement of what you are trying to achieve, as opposed to what you want done. For example, stating that you want to be driven from Cape Town to Johannesburg is a statement of what you want done and doesn’t leave much scope for advice. If, however, you were to say “I am in Cape Town. I need to be in Johannesburg for a meeting to close a R10 million deal no later than 10am tomorrow morning” is a statement of what you want to achieve. Such a statement allows us to explore the amount you can invest to realise this outcome and provide options as to how the outcome can be achieved.</p>
      <p>DataDIGEST worked closely with MyEdly.com and identified a freemium model for their launch. Individual teachers would get access to useful classroom applications for tracking attendance and student behaviour. Basic access to these features would be free, but the real value of the platform lies in collaboration with other teachers. At this point the school would need to pay a monthly subscription and get the ability to communicate directly with parents.</p>
    </div>
  </div>
</div>

<a id="mvp"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>Identify the MVP</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Having established the value proposition, agreed on a budget and a rough approach to realising the product, we need to identify what is the minimum, most important set of features to launch the product with. If the primary purpose of your product is to be a travel site that sells discounted airline seats then that is what you launch with. Hotels, car hire and so on can probably come later. The point of a Minimum Viable Product is to get to a point where the business or product idea is validated for the least investment of time and money. It is also important that you as the product or business owner understand some of the compromises that will need to be made in terms of time to launch vs feature completeness vs quality vs technical debt, etc.</p>
      <p>Having established the value proposition, we identified that the key focus of the MVP was to get teachers onboard and using the system several times a week. This dictated that every day features like attendance and simple behaviour tracking and reporting were included in the MVP whilst more complicated behaviour policy creation and other detailed reporting were deferred. The other critical part of the MVP was to create an onboarding process that guided the user through the process of creating their school and getting initial student data loaded as the system cannot be used until this data is present.</p>
    </div>
  </div>
</div>

<a id="metrics"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>Establish metrics</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Hand in hand with identifying the MVP is to work out what metric or metrics you will measure to gauge the success or otherwise of your initial product idea. It is important that this is done early because what you choose to measure will influence decisions about how to implement features. The instrumentation to produce the measurements may need to be built into the product as well. In fact, having a good instrumentation framework in the solution from the outset makes the subsequent Measure and Adapt phases much easier to achieve.</p>
      <p>Both DataDIGEST and MyEdly.com agreed that the key metrics were all around user engagement, conversion and retention. These metrics actually extended to the marketing campaigns that would surround the launch of the product. The following set of metrics were setup for the MVP launch:</p>
      <ol>
          <li>Number of people seeing a social media ad for the platform;</li>
          <li>Percentage of people seeing the ad who clicked through to the home page;</li>
          <li>Percentage of people landing on the home page who became users;</li>
          <li>Percentage of users using the system at least 3 times a week.</li>
      </ol>
    </div>
  </div>
</div>

<a id="launch"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>Build and launch fast</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md">
      <p>Your technical implementation team needs to understand the environment it is operating in and adapt its processes and standards appropriately. If the product owner has made a call that time to market trumps quality for the MVP then the technical team should not spend weeks targeting 100% test coverage or optimising for a 10% performance improvement at volumes of traffic not expected until the third year. What the team should be investing in however, is the capacity to get updates to the product in front of users quickly, safely and repeatedly. Automation of these processes is key here.</p>
      <p>The DataDIGEST technical team utilised Git hosted in Visual Studio Team Services (VSTS) for code management. VSTS also provided the ability to incorporate continuous integration and automated build and deployments to a testing environment (a Microsoft Azure App Service). The build was organised in two week iterations with a working deployment available to demo at the end of each iteration. A technical team of 4, working closely with the MyEdly.com stakeholder, completed the MVP in 8 sprints.  The launch was straightforward and uneventful. No major issues were encountered and several minor issues were rectified in a matter of minutes.</p>
    </div>
  </div>
</div>

<a id="measure"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>Measure</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Your baby has been introduced to the world and users are hopefully flocking to it. Now is the time to start analysing the metrics you have identified and the system is busy collecting. The technical team is probably simultaneously moving onto the next tranche of features based on the initial plan. If the metrics are starting to suggest a trend you can consider reaching out to certain users to validate your interpretation of the numbers. Keep striving to identify the one metric that matters.</p>
      <p>The MyEdly.com metrics were gathered from Facebook campaign statistics, Google Analytics for site visitors, behaviour and cohort analysis and Crazy Egg for heat maps of user interactions on each page of the system. Metrics were gathered in near real-time, aggregated and reported daily in the execution team meeting involving DataDIGEST and MyEdly.com staff.</p>
    </div>
  </div>
</div>

<a id="adapt"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>Adapt</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Challenge all your assumptions and preconceptions. Challenge the validity of the metrics. The key to the Adapt stage is for everyone to let go of their egos. Getting the right answer is what matters, not who is right or wrong. Let the data guide you and adapt the feature, metrics and processes appropriately.</p>
      <p>The metrics very quickly pointed to a trend that invalidated one of the team’s initial assumptions. It became clear that people were responding to the marketing campaign late at night and predominantly from mobile devices. This suggested that MyEdly.com’s message resonated with them and they wanted to check out the app, but they probably didn’t have access to, or inclination to enter, all their students, so the conversion rate was lower than anticipated. To adapt to this, we completely reworked the onboarding process, with an objective of getting a new user to the platform dashboard in 2 steps. We also introduced a new metric - number of onboarding dropouts - to measure the efficacy of the process. These changes were made within a single, two-week development iteration and once released MyEdly.com saw significant improvements in onboarding conversions. </p>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Today MyEdly.com continues to go from strength to strength, making 100s of teachers more effective every day. DataDIGEST continues to provide product development support.</p>
      <p>DataDIGEST can help you unleash the potential of your product. <a href="index.html" class="dataDigestGold"><b>Start the conversation today</b></a>.</p>
    </div>
  </div>
</div>

{{>contactusbutton}}













