---
_options: 
  layout: src/layouts/main.static.hbs
  partials:
    - src/partials/theform.static.hbs
    - src/partials/mobiletechslider.static.hbs
    - src/partials/techslidercard.static.hbs
    - src/partials/clientslider.static.hbs
    - src/partials/scrollicon.static.hbs
    - src/partials/scrolltarget.static.hbs
    - src/partials/contactusbutton.static.hbs
    - src/partials/howwework.static.hbs
    - src/partials/whoweare.static.hbs
    
page-id: mobile-app-development
title: Mobile app development iOS and Android
current-nav-item: mobile-app-development
---
{{>theform leadMessage='There is more to your app than meets the eye. Start a <span class="dataDigestGold"><b>conversation</b></span> now to find out more' 
    conversion_event_code='AW-794917297/nGm6CI3LxZcBELHzhfsC'
    phone_number='+27212005969'
    phone_number_formatted='+27 (0)21 200 5969'
    form_link='contactus.html'}}

{{>scrollicon}}

<div class="hide-on-landscape theWords">
  <div>
    Xamarin<br/>
    iOS<br/>
    Android<br/>
    PWAs<br/>
    <a href="index.html" class="">More</a>
  </div>
</div>

<div class="mantra">
  <div class="txt">
    <span class="inner">
     imagination
      <!--Potential-->
      <span class="block">un</span> 
      leashed
    </span>
  </div>
</div>

<div id="aa_particles"></div>




{{>whoweare}}

<div class="container">
  <div class="row">
    <div class="col-md">
      <h2>There's more to your app than meets the eye</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Sure, looks are important, but the technology choices behind the scenes will have a big impact on the success or failure of your 
        mobile app development. At DataDIGEST, we care about the full stack, from the stunning designs you see through to the metal running 
        the services in the cloud. Let us help you choose the best tools and technology for your specific needs, now and in the future.</p>
      <h2></h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-4 col-xl-4">
      <h3>Progressive Web Apps</h3>
      <p>A Progressive Web App (PWA) is a standard web application you access from your browser, which has been progressively enhanced so that 
        it can be installed as an application on desktops running Windows and Linux (Mac is coming soon), and all mobile devices. When installed, 
        it acts like a native application in that environment, i.e. is fast, works offline and can integrate with features of the mobile device or 
        desktop (like a camera).</p>
    </div>
    <div class="col-12 col-lg-4 col-xl-4">
      <h3>iOS and Android Apps</h3>
      <p>We are great advocates of Xamarin for mobile application development. For most general purpose apps, you can write once and have your app work on both iOS and Android devices.</p>
      <p>Our love of Xamarin aside, from state of the art security apps to award-winning educational games, we’ve got down and dirty with developing specifically for iOS or Android 
        using Objective-C, Swift, Java and C++. We will work with you to understand your requirements before helping you choose the technology that will work best for your app. </p>
    </div>
    <div class="col-12 col-lg-4 col-xl-4">
      <h3>Cloud Backends</h3>
      <p>If you want to offer features across more than one device then you are going to need to supplement your mobile application with a cloud backend. We recommend going with one of 
        the big cloud providers: Amazon Web Services (AWS), Microsoft Azure or Google Cloud Platform. These providers offer tools that 
        make development cheaper plus they offer better security than some popular local hosting companies.</p>
        <p>As a Microsoft Cloud Partner we can offer you preferential rates on Microsoft Azure services.</p>
    </div>
  </div>

  <div class="row d-none d-sm-none d-md-none d-lg-flex d-xl-flex">
    <div class="col"><a href="progressive-web-apps.html" class="btn btn-sm btn-primary btn-block">Learn more</a></div>
    <div class="col"></div>
    <div class="col"></div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md">
      <h2 class="text-center">Some of our clients</h2>
    </div>
  </div>
</div>

{{>clientslider}}

{{>howwework}}

{{>contactusbutton}}