<div class="footer">
  <div class="container">
    <div class="row mb-4">
      <div class="col text-center">
        <a href="https://twitter.com/datadigestsa" target="_blank" rel="noopener noreferrer"><i class="fab fa-3x fa-twitter-square"></i></a>
        <a href="https://www.facebook.com/datadigest/" target="_blank" rel="noopener noreferrer"><i class="fab fa-3x fa-facebook-square"></i></a>
        <a href="https://www.linkedin.com/company/datadigest/" target="_blank" rel="noopener noreferrer"><i class="fab fa-3x fa-linkedin"></i></a>
        <a href="https://www.medium.com/datadigest/" target="_blank" rel="noopener noreferrer"><i class="fab fa-3x fa-medium"></i></a>
        <p class="copyRight"></p>
      </div>
    </div>
  </div>
</div>

<script src="/js/vendor.js"></script>
<script src="https://code.jquery.com/jquery-3.3.1.min.js" integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8=" crossorigin="anonymous"></script>
<script src="/js/bootstrap.bundle.min.js"></script>
<script src="/js/slick.min.js"></script>
<script src="/js/particles.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/js-cookie@2/src/js.cookie.min.js"></script>
<script src="/js/app.js" onload="require('src/js/initialize.js');"></script>
<script src="/js/util.js"></script>
