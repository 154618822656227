---
_options: 
  layout: src/layouts/main.static.hbs
  partials:
    - src/partials/contactusbutton.static.hbs
  
title: Your technology product unleashed
description: Smart teams do more with less. Read about our successful upgrade of a major PHP system for a fraction of the cost of Indian outsourcing providers.
page-id: project
current-nav-item: Project
---
<div class="content mantra">
  <div class="txt">
    <span class="inner">
     your product
      <!--Potential-->
      <span class="block">un</span> 
      leashed
    </span>
  </div>
</div>

<div id="content_particles"></div>

<div class="container mt-2">
    <div class="row">
        <div class="col">
            <h1>Case Study: Major PHP Upgrade Completed for 10% of Indian Competitor's Quote</h1>
        </div>
    </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
     <p>Experience and skill trump weight of numbers in software development. During 2019 we upgraded a major PHP Membership System for 10% of the forecast cost and 15% of the forecast time of the incumbent Indian support provider. </p>
     <ol>
         <li><a href="#system">The System</a></li>
         <li><a href="#brief">The Brief</a></li>
         <li><a href="#challenge">The Challenge</a></li>
         <li><a href="#solution">The Solution</a></li>
         <li><a href="#bottomLine">The Bottom Line</a></li>
     </ol>
    </div>
  </div>
</div>

<a id="system"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>The System</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Our client was a major Australian voluntary sporting organisation. Their membership system was written in PHP in the early-2000s and had not been receiving regular maintenance updates. As a result, the version of PHP it was using was no longer receiving security updates and in addition a number of security vulnerabilities had been identified. </p>
    </div>
  </div>
</div>

<a id="brief"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>The Brief</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
        <ul>
            <li>Update the version of PHP used from 5.6 to 7.3.</li>
            <li>Identify and fix all instances of several security vulnerability types.</li>
            <li>Ensure no regression.</li>
        </ul>
   </div>
  </div>
</div>

<a id="challenge"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>The Challenge</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Our client had already received a quote of in-excess of AU$1 million and an expected time to delivery of 2 years from their Indian outsourced development partner. The client could afford neither the cost nor the delay in ensuring that their member’s data was secure.</p>
      <p>Our challenge was to find a way to quickly understand how the complex system worked, identify and upgrade all the system’s dependencies that were incompatible with PHP 7.3, identify and change all the source code that would cause errors in PHP 7.3 and verify that the system worked in the same way after our changes as it did beforehand. And on top of all this, we had to find a way to do this whilst being an order of magnitude cheaper than the developers who worked on the system every day. </p>
    </div>
  </div>
</div>

<a id="solution"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>The Solution</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md">
      <p>From the outset it was clear that we had to find a way to automate as much of the work as possible, so instead of our developers actually changing PHP code or executing tests, they needed to focus on writing scripts to do this work for them. The key components to our solution were as follows.</p>
        <p><b>Docker</b>: It is difficult to run 2 versions of PHP side by side on a development machine (or a production server for that matter). Step 1 was to containerise the system using Docker so that our developers could easily move back and forth between PHP 5.6 and 7.3.</p>
        <p><b>Automated Tests with Katalon Studio</b>: There were no tests or test plans that we could rely upon to perform regression testing. We spent 4 weeks writing automated User Interface tests for the entire system running on PHP 5.6. This automated test suite could then be run against the upgraded system to verify everything still worked as expected. The added benefit of writing these automated tests was that it familiarised our developers with the system, allowing them to work faster when we moved onto the upgrade phase of the project. The test suite ended up taking 24 hours to run in its entirety, but probably saved 10 days per run when compared with a person manually performing the testing.</p>
        <p><b>Python Scripts</b>: With an automated test suite in place, our team could upgrade the version of PHP used to 7.3 and run the tests to identify problems. When a problem was identified, a Python script was written to locate all instances of that problem and replace the problem with PHP 7.3 compliant code. Then the automated test suite was run again to verify that the problem had been corrected. This process was repeated until all tests in the test suite passed.</p>
    </div>
  </div>
</div>

<a id="bottomLine"></a>
<div class="container">
  <div class="row">
    <div class="col">
      <h2>The Bottom Line</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>We delivered the upgraded system to the client in just 4 months. The client found no regression bugs during User Acceptance Testing. In addition to the upgrade, the client also received a substantial automated testing suite to help ensure quality as well as a containerised solution to drastically reduce the time needed for new developers to get up and running with the system in the future. </p>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Contact us today to find out how our expertise can benefit your existing system.</p>
    </div>
  </div>
</div>

{{>contactusbutton}}













