<h3>Slash Hosting Costs with a Serverless Architecture</h3>
<div class="row d-flex justify-content-center align-items-center">
    <img class="margin-10" src="/img/blazor.png" alt="Blazor" title="Blazor"> <span class="fa fa-plus"></span>
    <img class="margin-10" src="/img/azure-functions.jpeg" alt="Azure Functions" title="Azure Functions"> <span class="fa fa-plus"></span>
    <img class="margin-10" src="/img/storage.png" alt="Azure Storage" title="Azure Storage"> <span class="fa fa-equals"></span>
    <img class="margin-10" src="/img/savings.png" alt="Savings" title="Savings">
</div>
<p>
    Sensitive to monthly hosting costs? A serverless solution allows you to only pay for what you use. DataDIGEST's serverless
    architecture combines a <a href="https://dotnet.microsoft.com/apps/aspnet/web-apps/blazor" target="_blank">Blazor Web Assembly</a> app hosted in an <a href="https://docs.microsoft.com/en-us/azure/storage/blobs/storage-blob-static-website" target="_blank">Azure Storage Static Website</a>,
    <a href="https://azure.microsoft.com/en-us/services/functions/" target="_blank">Azure Functions</a> for the API
    and Azure Table and Blob Storage for data storage. The net result? A low-cost hosting solution with superb scalability that is
    wrapped in the safety blanket of Azure's exemplary security and threat protection.
</p>
<p class="panel">
    <a class="primaryLink" href="contactus.html">See for yourself</a> - drop us a line and we'll send you a full cost comparison.
</p>
