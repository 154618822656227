{{#if analytics-id}}
{{#ifEq analytics-id 'AW-XXXXXXXXX'}}
<!-- Google TagManager: change AW-XXXXXXXXX (tagmanager-id) to be your site's ID in `/layouts/main.static.hbs` -->
{{else}}
<script async src="https://www.googletagmanager.com/gtag/js?id={{tagmanager-id}}"></script>
<script>
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '{{tagmanager-id}}');
</script>
{{/ifEq}}
{{/if}}




