<h3>Data Solutions for Every Scenario</h3>
<div class="row d-flex justify-content-center">
    <img class="margin-10" src="/img/cosmosdb.png" alt="Azure CosmosDB" title="Azure CosmosDB">
    <img class="margin-10" src="/img/azure-sql.png" alt="Azure SQL Database" title="Azure SQL Database">
    <img class="margin-10" src="/img/storage.png" alt="Azure Storage" title="Azure Storage">
</div>
<p>
    Today's technologists are spoilt for choice when deciding on a data storage technology. The days of reaching for
    SQL Server or MySQL without even thinking about it are over. The right choice for your solution will depend upon your unique
    circumstances. Azure provides hosted solutions ranging from the traditional relational model with <a target="_blank" href="https://azure.microsoft.com/en-us/services/sql-database/">Azure SQL Database</a>, through
    easy to use and cheap <a href="https://azure.microsoft.com/en-us/services/storage/" target="_blank">Azure Storage</a> (tables, blobs and files) to a globally scaled NoSQL solution in <a href="https://azure.microsoft.com/en-us/services/cosmos-db/" target="_blank">CosmosDB</a>.
    Having delivered solutions with all these technologies, DataDIGEST is uniquely placed to advise you on choosing the right technology
    for your requirements and budget.
</p>
<p class="panel">
    <a class="primaryLink" href="contactus.html">Request our Data Storage Cheat Sheet</a> - we've distilled everything we've learned about choosing the right data storage technology into one simple document.
</p>
