---
_options:
  partials:
    - src/partials/head_google_analytics_scripts.static.hbs
    - src/partials/head_google_tagmanager_scripts.static.hbs
    - src/partials/head_msformsembed_scripts.static.hbs
    - src/partials/head_metatags_og.static.hbs
    - src/partials/head_metatags_twitter.static.hbs
    - src/partials/head_favicons.static.hbs
    - src/partials/head.static.hbs
    - src/partials/header.static.hbs
    - src/partials/nav.static.hbs
    - src/partials/footer.static.hbs
    - src/partials/sitemapbuilder.static.hbs
    - src/partials/techslider.static.hbs
    - src/partials/techslidercard.static.hbs
    - src/partials/clientslider.static.hbs
    - src/partials/mobiletechslider.static.hbs
    - src/partials/contactusbutton.static.hbs
    - src/partials/howwework.static.hbs
    - src/partials/whoweare.static.hbs
    - src/partials/appservice.static.hbs
    - src/partials/serverless.static.hbs
    - src/partials/azure_data.static.hbs
    - src/partials/flexible_resourcing.static.hbs

"nav-item":
  [
    {
      "name": "Home",
      "url": "index.html"
    },
    {
      "name": "Business",
      "url": "business.html",
    },
    {
      "name": "People",
      "url": "people.html"
    },
    {
      "name": "Product",
      "url": "product.html"
    },
    {
      "name": "Dev-SA",
      "url": "software-development-south-africa.html"
    },
    {
      "name": "Dev-AU",
      "url": "software-development-australia.html"
    }
  ]

site-base-url: https://www.datadigest.co.za
site-name: DataDIGEST
site-title: Your software technology partner
site-description: Technology company with presence in Cape Town and Sydney offering software development, app development, cloud migrations, integrations and advice
site-keywords: software development, cloud applications, web development, app development, software development south africa, azure development, .net development, java development, react development, php development, ios development, android development
site-image-url: https://www.datadigest.co.za/img/dd-full-orb-top.png
site-twitter-handle: datadigestsa
analytics-id: UA-63790581-1
tagmanager-id: AW-794917297
---{{#if is-sitemap}}{{>sitemapbuilder}}{{else}}
<!DOCTYPE html>
<html>
  <head>
  {{>head}}
  </head>
  <body {{#if page-id}}id="{{page-id}}"{{/if~}} class="{{#if has-sidebar}}with-sidebar {{/if~}}{{page-classes}}">
    <header role="banner" data-ga-category="header">
      {{>header}}
    </header>
    <nav id="site-navigation">
      {{>nav}}
    </nav>
    <main id="main">
      {{content}}
    </main>
    <footer>
    {{>footer}}
    </footer>
  </body>
</html>
{{/if}}
