---
_options: 
  layout: src/layouts/main.static.hbs
  
title: Start the conversation
description: Whatever you are looking to achieve, it begins with a conversation.
page-id: contact 
current-nav-item: Contact 
---
<div id="content_particles">
  <div class="content mantra">
  <div class="txt">
    <span class="inner">
     your idea
      <!--Potential-->
      <span class="block">un</span> 
      leashed
    </span>
  </div>
</div>
</div>

<div class="container mt-2" >
    <div class="row">
        <div class="col">
            <h1>We'd Love to Hear From You</h1>
        </div>
    </div>
</div>

<div class="container mt-2" >
    <div class="row">
        <div class="col">
            <h3>Quick Actions</h3>
        </div>
    </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>Use the following buttons to quickly get started with us.</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a href="estimate.html" target="_blank" class="btn btn-block btn-primary btn-lg">Request an Estimate</a>
    </div>
  </div>
  <div class="row padding-top-10">
    <div class="col">
      <a href="nda.html" target="_blank" class="btn btn-block btn-primary btn-lg">Request a NDA</a>
    </div>
  </div>
  <div class="row padding-top-10">
    <div class="col">
      <a href="callback.html" target="_blank" class="btn btn-block btn-primary btn-lg">Request a Callback</a>
    </div>
  </div>
    <div class="row padding-top-10">
        <div class="col">
            <a href="https://cdn.forms-content.sg-form.com/cdb829b8-c4ee-11ea-91c4-9a7a264a77e3" target="_blank" class="btn btn-block btn-primary btn-lg">Subscribe to our Newsletter</a>
        </div>
    </div>
</div>

<div class="container mt-2" >
    <div class="row">
        <div class="col">
            <h3>General Enquiries</h3>
        </div>
    </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p><b>Email:</b> info@datadigest.net</p>
      <p><b>Phone:</b> +27 21 200 5969</p>
      <p><b>Address:</b> Baker Square Block 1, De Beers Avenue, Paardevlei, Somerset West, 7130, South Africa</p>
    </div>
  </div>
</div>





















