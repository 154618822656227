<div class="container">
  <div class="row">
    <div class="col-md">
      <h2>How we work</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md">
      <p>We provide small, highly-experienced technical teams that get things done, fast. We execute in short, tightly focussed iterations with clearly defined deliverables. We produce code, documentation and processes that can be handed over to another team for ongoing development and maintenance.</p>
      <p>Each of our managing directors have been in the software industry for 20 years. They bring this experience to our clients by personally working on every project. The rest of our development teams are comprised of experts with at least 10 years experience.</p>
      <p>All this experience allows us to offer a 6-week trial to all new clients on our project and yearly agreements. We provide a money-back guarantee that you will be happy with our service at the end of this period. Contact us today to find out more and get started.</p>
    </div>
  </div>
</div>
