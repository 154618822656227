---
_options: 
  layout: src/layouts/main.static.hbs 
  partials:
    - src/partials/contactusbutton.static.hbs
  
title: Your people unleashed with technology
description: Good technical staff are hard to find, expensive and difficult to manage. Are you getting the most from your investment in your technical teams? Use the experience we’ve gained building, running and improving technical product development teams around the world to improve yours.
page-id: people 
current-nav-item: People 
---
<div class="content mantra">
  <div class="txt">
    <span class="inner">
     your people
      <!--Potential-->
      <span class="block">un</span> 
      leashed
    </span>
  </div>
</div>

<div id="content_particles"></div>

<div class="container mt-2">
    <div class="row">
        <div class="col">
            <h1>Unleash the potential in your people</h1>
        </div>
    </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
        <p>If technology can unleash the potential of your business, so can technical staff provide you with a competitive advantage. But building a technical team in-house or engaging external contractors or agencies is a significant investment. You need to be sure that you are investing your resources wisely and getting the best return on that investment.</p>
    </div>
  </div>
</div>

<div class="container">
    <div class="row">
        <div class="col">
            <h2>With your own team</h2>
        </div>
    </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
        <h3>Process Review and Improvement</h3>
        <p>There is a wealth of research and suggested approaches for making the software development process more efficient. Yet implementing an improved process in organisations can be a significant challenge. Successful change requires commitment from both management and the team and often benefits from being facilitated by a neutral party. Just as we should always walk the value chain before embarking on a new project or product, so we should understand the value chain of internal technical teams. Also, like product development, process improvement is a voyage of discovery and incremental change.</p>
        <h3>Plug a skills gap</h3>
        <p>Building a technical team is a massive challenge for SMEs. By necessity you will fill your team with generalists, but from time to time you will need specialist skills in a particular area. Being able to recognise the need when it arises is a critical skill. Once the need is identified, having access to specialists at short notice and on short terms can be highly advantageous to your team’s efficiency, provided they can fit into your team’s culture and processes quickly and seamlessly.</p>
        <h3>Leadership/Mentorship</h3>
        <p>Where do you start when building a team as an SME? Splash out on an expensive senior or team lead and hope they deliver or spread the risk a little with two or three less experienced developers? If you have an inexperienced team, consider how you can provide technical leadership and mentorship. Provide time and encouragement to attend meet-ups and conferences. If you feel you personally lack the technical acumen to be a technical mentor, engage someone to fulfil the role on a part time or ad-hoc basis. Look to that person to perform one on ones, build career plans, recommend training, conduct code reviews, etc.</p>
        <h3>Training</h3>
        <p>You need to invest in your people to get the best return and to keep the best people. Training is a key part of this. Ensure your team has a training plan with specific outcomes. There are plenty of online training providers that you can subscribe to which provide access to courses that cover most of your team’s needs. Consider getting team members to present a session to the rest of the team on what they have learnt, as having to teach and communicate reinforces lessons learned.</p>
    </div>
  </div>
</div>

<div class="container">
    <div class="row">
        <div class="col">
            <h2>With external contractors/freelancers</h2>
        </div>
    </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
        <p>Not every business can afford the time or money to build an internal technical team. The attractions of cheap resources on sites like freelancer.com can appear tantalising. Cost and quality are often inextricably linked, but there are several steps you can take to maximise your chances of success when engaging contractors or freelancers.</p>
        <h3>Control Your Own Source and Environments</h3>
        <p>There is a lot of behind the scenes activity in a technical project: maintaining source code, testing environments, builds and deployments, etc. You will be best served by owning these components yourself rather than entrusting their creation and maintenance to the first freelancer you engage. You want to know that you always have access to what you are paying for. </p>
        <h3>Clearly Define Requirements</h3>
        <p>Freelancing or external contractual relationships work best when the requirements are as tightly defined as possible. The more you leave open to interpretation the greater the scope for you to be left unhappy or to end up in dispute with the technical party. Rather than asking for the whole system from a single freelancer, break your requirements down and have multiple freelancers do smaller, more defined pieces of work. Accept that you need to make a significant personal investment in the process, or engage someone to do this on your behalf. </p>
        <h3>Upfront Architecture Design</h3>
        <p>As we’ve mentioned above, arrangements with freelancers work best when the requirements are clearly defined. This extends to technical requirements as well as functional ones. Consider engaging an experienced architect or technical consultant to define the architecture and coding standards for your product or project. When engaging a freelancer you can then explicitly state as a requirement that they conform to this architecture and meet the standards. Failure to do so means you don’t have to pay. </p>
        <h3>Quality Assurance</h3>
        <p>There are many aspects to quality assurance or testing. Most obviously there is testing that makes sure that the features you’ve requested have been delivered as required and expected. Then you also need to make sure that any system developed is secure and that it will perform acceptably when it is used in a real-world scenario. Finally, is the system designed and written in such a way that it can be easily modified and maintained in the future? Ongoing cost of ownership is an often overlooked aspect of a system. Don’t rely on those who developed the system for you to provide an answer on these points…get a second opinion.</p>
    </div>
  </div>
</div>

<div class="container">
    <div class="row">
        <div class="col">
            <h2>For all scenarios</h2>
        </div>
    </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
        <p></p>
        <h3>Identify meaningful success metrics</h3>
        <p>Here are two truisms you have (or will) no doubt hear when you are working with techies:</p>
        <ul>
            <li>“You can’t improve what you don’t measure”;</li>
            <li>“It’s impossible to measure what I do”</li>
        </ul>
        <p>Finding the right metrics to use is difficult, but not impossible. There is no one size fits all approach, but there are certainly some things to avoid. Measuring input (like hours worked, lines of code written, etc) is easy but counterproductive - you have a problem to solve or opportunity to realise and measuring input rewards the most inefficient approach.</p>
        <p>Rather focus on establishing metrics with your techies that measure output or the overall efficiency of the process. This may be lead time, story points delivered, velocity, defect rate, etc. What you measure will influence the process, so choose what is important for your particular circumstances.</p>
        <h3>Periodic Health Check</h3>
        <p>A hallmark of all successful organisations is that they introspect and look for ways to get better all the time. In approaches such as Scrum this is engrained in the regular cycle of work as the Sprint Retrospective. Technical product development is complex, so a periodic checkup should consider:</p>
        <ol>
            <li>Are the processes in place working as they should? Could they be improved?</li>
            <li>Is the project or product meeting its objectives? Is it on time? Is it delivering value?</li>
            <li>How much technical debt has been accrued? Will the team be able to modify or maintain the system economically?</li>
            <li>Are the metrics you are measuring still the right ones for the team/project/product?</li>
        </ol>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
        <p>DataDIGEST can help you get the most from your team or partners. <a href="index.html" class="dataDigestGold"><b>Start the conversation today</b></a>.</p>
    </div>
  </div>
</div>

{{>contactusbutton}}

