
<div class="container">
    <div class="row">
        <div class="col-md">
            <div class="techSlider">
                {{>techslidercard imgSrcUrl='/img/xamarin.png' imgTitle='Cross platform mobile App software development with Xamarin'}}
                {{>techslidercard imgSrcUrl='/img/android.png' imgTitle='Native Android App software development'}}
                {{>techslidercard imgSrcUrl='/img/iOS.png' imgTitle='Native iOS App software development'}}
                {{>techslidercard imgSrcUrl='/img/progressive-web-apps.png' imgTitle='Progressive web App software development'}}
                {{>techslidercard imgSrcUrl='/img/aws.png' imgTitle='Hosting of developed software solutions on AWS'}}
                {{>techslidercard imgSrcUrl='/img/azure.png' imgTitle='Hosting of developed software solutions on Microsoft Azure'}}
                {{>techslidercard imgSrcUrl='/img/gcp.png' imgTitle='Hosting of developed software solutions on Google Cloud Platform GCP'}}
                {{>techslidercard imgSrcUrl='/img/azure-storage.png' imgTitle='Development of software solutions that use Microsoft Azure Storage'}}
                {{>techslidercard imgSrcUrl='/img/azure_devops.png' imgTitle='Development of software solutions with project management, source control, and continuous integration on Azure DevOps'}}
            </div>
        </div>
    </div>
</div>