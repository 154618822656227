<meta charset="UTF-8">
<meta name="viewport" content="initial-scale=1.0" />
<title>{{#if title}}{{title}}{{else}}{{#if site-title}}{{site-title}}{{else}}DataDIGEST{{/if}}{{/if}} | {{#if site-name}}{{site-name}}{{else}}DataDIGEST{{/if}}</title>
<meta name="title" property="title" content="{{#if title}}{{title}}{{else}}{{#if site-title}}{{site-title}}{{else}}DataDIGEST{{/if}}{{/if}} | {{#if site-name}}{{site-name}}{{else}}DataDIGEST{{/if}}">
<meta name="description" property="description" content="{{#if description}}{{description}}{{else}}{{#if site-description}}{{site-description}}{{/if}}{{/if}}">
<meta name="keywords" property="keywords" content="{{#if keywords}}{{keywords}}{{else}}{{#if site-keywords}}{{site-keywords}}{{/if}}{{/if}}">
{{>head_favicons}}
{{>head_metatags_og}}
{{>head_metatags_twitter}}
<link rel="stylesheet" href="/css/bootstrap.min.css" />
<link rel="stylesheet" href="/css/custom.css" />
<link rel="stylesheet" href="/css/slick.css" />
<link rel="stylesheet" href="/css/slick-theme.css" />
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous">
<script src='https://www.google.com/recaptcha/api.js'></script>
{{>head_google_analytics_scripts}}
{{>head_google_tagmanager_scripts}}
{{>head_msformsembed_scripts}}

