---
_options:
  layout: src/layouts/main.static.hbs
  partials:
    - src/partials/theform.static.hbs
    - src/partials/techslider.static.hbs
    - src/partials/techslidercard.static.hbs
    - src/partials/clientslider.static.hbs
    - src/partials/scrollicon.static.hbs
    - src/partials/scrolltarget.static.hbs
    - src/partials/contactusbutton.static.hbs
    - src/partials/howwework.static.hbs
    - src/partials/whoweare.static.hbs
    - src/partials/flexible_resourcing.static.hbs

page-id: index
current-nav-item: Home
---

{{>theform leadMessage='Small, highly-experienced teams that get things done, <span class="dataDigestGold"><b>fast</b></span>'
    conversion_event_code='AW-794917297/-muBCJmluokBELHzhfsC'
    phone_number='+27212005969'
    phone_number_formatted='+27 (0)21 200 5969'
    form_link='contactus.html'}}


{{>scrollicon}}

 <div class="hide-on-landscape theWords">
  <div>
    Blazor Web Applications<br/>
    Xamarin Mobile Applications<br/>
    .NET Core APIs<br/>
      <a href="azure.html" class="">Microsoft Azure Experts</a><br/>
    6-Week Money-Back Trial<br/>
    Consulting and Project Rescue
  </div>
</div>

<div class="mantra">
  <div class="txt">
    <span class="inner">
     imagination
      <!--Potential-->
      <span class="block">un</span>
      leashed
    </span>
  </div>
</div>

<div id="aa_particles"></div>

{{>whoweare}}

<div class="container">
  <div class="row">
    <div class="col-md">
      <h2>Unleashing Potential with Technology</h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col">
      <p>
        From short-term consulting, through to assembling a complete product development and delivery team, we can help you
        unleash the potential in your business, your product and your people.
      </p>
      <h3>New Product Development</h3>
      <p>
        Your idea deserves a world-class execution team. We’ve helped launch web and mobile products in the
        education, travel, health and finance industries. Wherever you are in your product development journey,
        we can guide you through to the launch and evaluation of a Minimum Viable Product (MVP).
      </p>
      <p class="panel">
        <a class="primaryLink" href="product.html">Case Study: myedly.com</a> - read how DataDIGEST helped launch the only online platform for classroom teachers in South Africa.
      </p>
      <h3>Software Project Rescue</h3>
      <p>
        Is your software project missing deadlines, running over budget or just not delivering what you anticipated?
        Engage one of our teams to get things back on track. We have revived systems in many tech stacks, using automation and innovation to deliver results in a fraction of the time of our competitors.</p>
        <p class="panel">
            <a class="primaryLink" href="project-rescue.html">Case Study: PHP Upgrade</a> - read how DataDIGEST upgraded a major PHP Membership system for an Australian client in just 4 months.
        </p>
      <h3>Cloud Business Solutions</h3>
      <p>
        Microsoft Office 365 is much more than Word and Excel. Our experts can help you automate your business processes
        and build custom solutions that save you money and boost productivity.
      </p>
      {{>flexible_resourcing}}
      <h3>Advice</h3>
      <p>
        Our team possess a broad range of skills, but, as a Microsoft Partner, we have a specific focus on .NET Development,
        Azure and Office 365. We are happy to discuss providing advice and assistance to you in these areas.
      </p>
    </div>
  </div>
</div>

{{>howwework}}

<div class="container">
  <div class="row">
    <div class="col-md">
      <h2 class="text-center">Some of our clients</h2>
    </div>
  </div>
</div>

{{>clientslider}}

{{>contactusbutton}}
